import React, { useContext, createContext, useState } from 'react';
import { useEffect } from 'react';
import Cookies from 'universal-cookie';
import ENDPOINT from '../endpoints/endpoint';
import axios from 'axios';
import { useAuth } from './AuthContext'

export const TagContext = createContext();

export function useTag() {

    return useContext(TagContext)
}

export function TagProvider({ children }) {

    const { user } = useAuth()
    const [searchedTags, setSearchedTags] = useState([])
    const [searched, setSearched] = useState(false)
    



    const search = (value) => {


        if(value != '')
        {
            axios.post(`${ENDPOINT}search-tags`, {
                search: value
            }, {
                headers: {
                    Authorization: `Bearer ${user.attributes.token}`
    
                }
            })
                .then((response) => {
                  
                        setSearchedTags(response.data)
                        setSearched(true)

                   
                })
                .catch((error) => {
                    console.log(error.response)
                })
    
    
        }
        else{
            setSearched(false)
            setSearchedTags([])
        }

        



    }

   const searching = (value) => {
       setSearched(value)
   }

    const values = {
        search,
        setSearchedTags,
        searchedTags
      
        

    }

    return (
        <TagContext.Provider value={values}>
            {children}
        </TagContext.Provider>
    )
}
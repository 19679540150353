import React, { useState, createContext, useContext, useEffect } from 'react'

const CartContext = createContext();

export function useCart() {
    return useContext(CartContext);
}



export const CartProvider = ({ children }) => {
    const [cart, setCart] = useState([])
    const [updateCart, setUpdateCart] = useState(false)



    //cart modal
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    

    useEffect(() => {



        const storedCart  = JSON.parse(localStorage.getItem("cart") || "[]");


        if(storedCart){
            setCart(storedCart)
        }
        else{
            setCart([])
        }

        console.log(storedCart)


    }, [])

    // const storeCart = () => {

    //     const storedItems = localStorage.getItem('cart');
    //     if (storedItems) {
           
    //         localStorage.setItem('cart', JSON.stringify(cart))
    //     }
    //     else {


    //         localStorage.setItem('cart', JSON.stringify(cart))

    //     }
    // }


    const value = {
        setCart,
        cart,
        show,
        setShow,
        handleClose,
        handleShow,
        updateCart, 
        setUpdateCart
    }
    return (
        <CartContext.Provider value={value}>
            {children}
        </CartContext.Provider>
    )
}





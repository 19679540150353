import React, {useContext, useState} from "react";
import { Route, Redirect } from "react-router-dom";
import {useAuth} from '../contexts/AuthContext';

export const ProtectedRoute = ({
  component: Component,
  ...rest
}) => {

  const {user} = useAuth()
  
  return (
    <Route
      {...rest}
      render={props => {
        if (user !== null) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect to="/user-pages/login-1"
            />
          );
        }
      }}
    />
  );
};

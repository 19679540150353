import React, { useContext, createContext, useState } from 'react';
import { useEffect } from 'react';
import Cookies from 'universal-cookie';
import ENDPOINT from '../endpoints/endpoint';
import axios from 'axios';

export const AuthContext = createContext();
export const Consumer = AuthContext.Consumer;



export function useAuth() {

    return useContext(AuthContext)
}


//user validation auth context

export function AuthProvider({ children, location }) {
    const cookies = new Cookies();

    const [isAdmin, setIsAdmin] = useState()
    const [user, setUser] = useState()



    useEffect(() => {

        const auth = cookies.get('Noortap')
        if (auth) {
            setUser(auth)

            if (auth.type === 1) {
                setIsAdmin(true)
            }
            else {
                setIsAdmin(false)
            }
        }
        else {
            setUser(null)
        }
    }, [isAdmin])




    const login = async (email, password) => {

        const details = { email: email, password: password };


        return new Promise((resolve, reject) => {
            axios.post(`${ENDPOINT}login`, details)
                .then((response) => {
                    if (response.data.error) {
                       reject(response.data.data.message)
                    }
                    else if(response.data.data.email_verified_at === null)
                    {
                        const err = {
                            status: 401,
                            message: 'email not verified'
                        }
                        reject(err)
                    }
                    else {
                        setUser(response.data.data)
                        if(response.data.data.type === 1)
                        {
                            setIsAdmin(true)
                        }
                        else{
                            setIsAdmin(false)
                        }
                        cookies.set('Noortap', response.data.data, { path: '/' });
                        resolve(response)
                    }

                })
                .catch((error) => {
                    if(error.response.data.errors)
                    {
                        reject(error.response.data.errors)
                    }
                    else{
                        reject(error.response)
                    }
                   
                })

        })
    }

    const register = (values) => {

        var result;

        const { email, serial, password, confirm_password } = values;

        return new Promise((resolve, reject) => {

            axios.post(`${ENDPOINT}register`, {
                email: email,
                serial: serial,
                password: password,
                password_confirmation: confirm_password
            })
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    reject(error.response.data.errors)

                })
        })



    }


    const logout = () => {
        cookies.remove('Noortap', { path: '/' });
        setUser(null)
        localStorage.removeItem('cart');


    }

    const value = {
        user,
        login,
        register,
        logout,
        isAdmin
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}
import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import {useAuth} from '../contexts/AuthContext';


function SideBarNew(props) {
    const [menuState, setMenuState] = useState();
    const [location, setLocation] = useState(props.location);
    const {user} = useAuth();
    const [isAdmin, setIsAdmin] = useState(false)
    const [pathName, setPathName] = useState(props.location.pathname)

    

    useEffect(() => {
        if (props.location !== location) {
            onRouteChanged();
            setLocation(props.location)
            setPathName(props.location.pathname)

            const body = document.querySelector('body');
            document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
              
              el.addEventListener('mouseover', function() {
                if(body.classList.contains('sidebar-icon-only')) {
                  el.classList.add('hover-open');
                }
              });
              el.addEventListener('mouseout', function() {
                if(body.classList.contains('sidebar-icon-only')) {
                  el.classList.remove('hover-open');
                }
              });
            });
        }

        isPathActive(props.location)

      if(user)
      {
        if(user.type === 1)
        {
          setIsAdmin(true)
        }
        else{
          setIsAdmin(false)
        }
      }

    }, [props.location, user])

    const toggleMenuState = (menuState) => {

        if(menuState)
        {
          setMenuState(false)
        }
        else{
          setMenuState(true)
        }

    }

    const onRouteChanged = () => {
      document.querySelector('#sidebar').classList.remove('active');

    }

    const isPathActive = (path) => {
        return props.location.pathname.startsWith(path);
    }
    

    return(
        <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          {/* <li className="nav-item nav-profile">
            <a href="!#" className="nav-link" onClick={evt =>evt.preventDefault()}>
              <div className="nav-profile-image">
                <img src={ require("../../assets/images/faces/face1.jpg") } alt="profile" />
                <span className="login-status online"></span> change to offline or busy as needed
              </div>
              <div className="nav-profile-text">
                <span className="font-weight-bold mb-2">David Grey. H</span>
                <span className="text-secondary text-small">Project Manager</span>
              </div>
              <i className="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
            </a>
          </li> */}
          <li className={pathName === '/dashboard' ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard">
              <span className="menu-title">Dashboard</span>
              <i className="mdi mdi-home menu-icon"></i>
            </Link>
          </li>
          <li className={pathName === '/add-tap' ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/add-tap">
              <span className="menu-title">Add New Tap</span>
              <i className="mdi mdi-plus-box menu-icon"></i>
            </Link>
          </li>
          <li className={pathName === '/landing-page' ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/landing-page">
              <span className="menu-title">Purchase New Tag</span>
              <i className="mdi mdi-plus-box menu-icon"></i>
            </Link>
          </li>
         {
           isAdmin ? 
           <>
           {/* <li className={pathName === '/admin/registered-taps' ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/admin/registered-taps">
              <span className="menu-title">Registered Taps</span>
              <i className="mdi mdi-plus-box menu-icon"></i>
            </Link>
          </li> 
           */}
          
          <li className={isPathActive('/admin') ? 'nav-item active' : 'nav-item'}>
          <div className={menuState ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState(menuState)} data-toggle="collapse">
            <span className="menu-title">Admin</span>
            <i className="menu-arrow"></i>
            <i className="mdi mdi-account-key menu-icon"></i>
          </div>
          <Collapse in={menuState}>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item"> <Link className={isPathActive('/admin/register-new-tap') ? 'nav-link active' : 'nav-link'} to="/admin/register-new-tap">Register New Tap</Link></li>
              <li className="nav-item"> <Link className={isPathActive( '/admin/registered-taps' )? 'nav-link active' : 'nav-link'} to="/admin/registered-taps">Registered Taps</Link></li>
              <li className="nav-item"> <Link className={isPathActive( '/admin/edit-packages' )? 'nav-link active' : 'nav-link'} to="/admin/edit-packages">Edit Packages</Link></li>

            </ul>
          </Collapse>
        </li> </>: ''
         }
         
        </ul>
      </nav>
    )

}

export default withRouter(SideBarNew);
import React, { Component, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import "./App.scss";
import AppRoutes from "./AppRoutes";
import Navbar from "./shared/Navbar";
import Sidebar from "./shared/Sidebar";
import SideBarNew from "./shared/SideBarNew";
import SettingsPanel from "./shared/SettingsPanel";
import Footer from "./shared/Footer";
import VisitorLandingFooter from "./shared/VisitorLandingFooter";
import { AuthProvider } from "./contexts/AuthContext";
import { TagProvider } from "./contexts/SearchTagContext";
import {useParams} from 'react-router-dom';

import { CartProvider } from './contexts/CartContext'
import NavTwo from './PurchaseTag/components/shared/Nav';
import CheckoutModal from './PurchaseTag/components/shared/CheckOut-Modal';




function App(props) {
  const [isFullPageLayout, setIsFullPageLayout] = useState();
  const [visitorLanding, setVisitorLanding] = useState(false);
  const [loginStatus, setLoginStatus] = useState();
  const { tagUrl } = useParams();

  const [landingPage, setLandingPage] = useState(false)




  let navbarComponent = !isFullPageLayout ? (
    <Navbar location={props.location} />
  ) : (
    ""
  );
  
  let sidebarComponent = !isFullPageLayout ? <Sidebar /> : "";
  let sideBarNewComponent = !isFullPageLayout ? <SideBarNew /> : "";
  let SettingsPanelComponent = !isFullPageLayout ? <SettingsPanel /> : "";
  let footerComponent = !isFullPageLayout ? <Footer /> : "";
  let visitorLandingFooter = visitorLanding ? <VisitorLandingFooter/> : ""; 

  
    




  useEffect(() => {
    onRouteChanged()


  

  }, [props.location]);

  const onRouteChanged = () => {
    // console.log("ROUTE CHANGED");
    //   const  i18n  = props.i18n ;
    //   const body = document.querySelector('body');
    //   if (props.location.pathname === '/layout/RtlLayout') {
    //     body.classList.add('rtl');
    //     i18n.changeLanguage('ar');
    //   }
    //   else {
    //     body.classList.remove('rtl')
    //     i18n.changeLanguage('en');

    // props.location.pathname.startsWith(path);
    //   }

  //   window.scrollTo(0, 0);
  //   const fullPageLayoutRoutes = [
  //     "/user-pages/login-1",
  //     "/user-pages/register-1",
  //     "/user-pages/lockscreen",
  //     "/error-pages/error-404",
  //     "/error-pages/error-500",
  //     "/general-pages/landing-page",
  //     "/link-expired",
  //     "/user-pages/forgot-password",
  //   ];
  //   for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
  //     if (props.location.pathname === fullPageLayoutRoutes[i]) {
  //       setIsFullPageLayout(true);
  //       document.querySelector(".page-body-wrapper").classList.add("full-page-wrapper");
  //       break;
  //     }
  //     if (props.location.pathname.includes("/user-pages/email-verification")) {
  //       setIsFullPageLayout(true);
  //       document.querySelector(".page-body-wrapper").classList.add("full-page-wrapper");
  //       break;
  //     }
  //     if (props.location.pathname.includes("/tap")) {
  //       setIsFullPageLayout(true);
  //       setVisitorLanding(true)
  //       document.querySelector(".page-body-wrapper").classList.add("full-page-wrapper");

  //       break;
  //     } else {
  //       setIsFullPageLayout(false);
  //       document.querySelector(".page-body-wrapper").classList.remove("full-page-wrapper");
  //     }
  //   }
  // };
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = ['/user-pages/login-1', '/user-pages/register-1', '/user-pages/lockscreen', '/error-pages/error-404',
      '/error-pages/error-500', '/general-pages/landing-page', '/link-expired', '/user-pages/forgot-password', '/landing-page' , '/purchase/checkout-page', '/purchase/payment-page', '/purchase/cart'];
    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
      if (props.location.pathname === fullPageLayoutRoutes[i]) {
        setIsFullPageLayout(true)
        document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
        break;
      }
      if (props.location.pathname.includes('/user-pages/email-verification')) {
        setIsFullPageLayout(true)
        document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
        break;
      }

      if (props.location.pathname.includes("/tap")) {
              setIsFullPageLayout(true);
              setVisitorLanding(true)
              document.querySelector(".page-body-wrapper").classList.add("full-page-wrapper");
      
              break;
            }

      if (props.location.pathname.includes('/purchase/product-details')) {
        setIsFullPageLayout(true)
        document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
        break;
      }
      else {
        setIsFullPageLayout(false)
        document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
      }
    }




    if (props.location.pathname.includes('/landing-page') || props.location.pathname.includes('/purchase/product-details')
     ||  props.location.pathname.includes('/purchase/checkout-page')||  props.location.pathname.includes('/purchase/payment-page') || props.location.pathname.includes('/purchase/cart')) {
      setLandingPage(true)
    }
    else {
      setLandingPage(false)
    }


    // for(let i = 0; i < landingPage.length; i++){
    //   if(props.location.pathname.includes(landingPage[i])){
    //       setLandingPage(true)
    //   }
    //   else{
    //     setLandingPage(false)
    //   }
    // }
  }



 
  return (



    <AuthProvider>
    <div className="container-scroller">
      <CartProvider>
      <TagProvider>
      {landingPage ? <NavTwo /> : navbarComponent}
        <div
          className="container-fluid page-body-wrapper"
          
        >
          {/* {sidebarComponent} */}
          {sideBarNewComponent}
          <div className="main-panel">
            <div className={props.location.pathname.includes('/tap') ? 'visitor-landing' : 'content-wrapper'}>
              <AppRoutes />

              {SettingsPanelComponent}
            </div>
            {landingPage ? <Footer/> : footerComponent}
          
          </div>
        </div>
      </TagProvider>
      </CartProvider>
    </div>
  </AuthProvider>





    // <AuthProvider>
    //   <div className="container-scroller">
    //     <TagProvider>
    //       {navbarComponent}
    //       <div
    //         className="container-fluid page-body-wrapper"
            
    //       >
    //         {/* {sidebarComponent} */}
    //         {sideBarNewComponent}
    //         <div className="main-panel">
    //           <div className={props.location.pathname.includes('/tap') ? 'visitor-landing' : 'content-wrapper'}>
    //             <AppRoutes />

    //             {SettingsPanelComponent}
    //           </div>
    //           {footerComponent}
            
    //         </div>
    //       </div>
    //       <CartProvider>

    //         {landingPage ? <NavTwo /> : navbarComponent}
    //         <div className="container-fluid page-body-wrapper">
    //           {/* {sidebarComponent} */}
    //           {sideBarNewComponent}

    //           <div className="main-panel">
    //           <div className={props.location.pathname.includes('/tap') ? 'visitor-landing' : 'content-wrapper'}>
    //             <AppRoutes />

    //             {SettingsPanelComponent}
    //           </div>
    //           {footerComponent}
            
    //         </div>
              
    //         </div>
    //       </CartProvider>
    //     </TagProvider>
    //   </div>
    // </AuthProvider>
  );
}

//className={pathName === '/add-tap' ? 'nav-item active' : 'nav-item' }
// class App extends Component {
//   state = {}
//   componentDidMount() {
//     this.onRouteChanged();
//   }
//   render() {
//     let navbarComponent = !this.state.isFullPageLayout ? <Navbar /> : '';
//     let sidebarComponent = !this.state.isFullPageLayout ? <Sidebar /> : '';
//     let SettingsPanelComponent = !this.state.isFullPageLayout ? <SettingsPanel /> : '';
//     let footerComponent = !this.state.isFullPageLayout ? <Footer /> : '';
//     return (
//       <div className="container-scroller">
//         {navbarComponent}
//         <div className="container-fluid page-body-wrapper">
//           {sidebarComponent}
//           <div className="main-panel">
//             <div className="content-wrapper">
//               <AppRoutes />
//               {SettingsPanelComponent}
//             </div>
//             {footerComponent}
//           </div>
//         </div>
//       </div>
//     );
//   }

//   componentDidUpdate(prevProps) {
//     if (this.props.location !== prevProps.location) {
//       this.onRouteChanged();
//     }
//   }

export default withRouter(App);






import React, {useContext, useState} from "react";
import { Route, Redirect } from "react-router-dom";
import {useAuth} from '../contexts/AuthContext';

export const AdminRoutes = ({
  component: Component,
  ...rest
}) => {

  const {isAdmin} = useAuth()
  
  return (
    <Route
      {...rest}
      render={props => {
        if (isAdmin) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect to="/dashboard"
            />
          );
        }
      }}
    />
  );
};
import React from 'react'

function VisitorLandingFooter() {

    return (
        <footer className="footer-visitor-landing">
            <div className="d-sm-flex justify-content-center justify-content-sm-between py-2">
                <a href="https://noor.qa" target="_blank" style={{textAlign: 'center'}}>
                    <img style={{ maxWidth: '100px', maxHeight: '100px', marginLeft: 'auto', marginRight:'auto', display: 'block', width: 'auto' }} src={require("../../assets/images/noor_logo.png")} alt="Noor Digital Technology" />
                    <span class="d-block" style={{ color: '#3490dc' }}>   Noor Digital Technology | NOOR.QA</span></a>
                {/* <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © <a href="https://www.noor.qa/" target="_blank" rel="noopener noreferrer">noor.qa </a>{(new Date().getFullYear())}</span> */}
                {/* <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Free <a href="https://www.bootstrapdash.com/react-admin-templates/" target="_blank" rel="noopener noreferrer"> react admin </a> templates from BootstrapDash.com.  </span> */}
            </div>
        </footer>
    )
}

export default VisitorLandingFooter
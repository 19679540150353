import React, { useState, useEffect } from 'react'
import Badge from 'react-bootstrap/Badge';
import { useAuth } from '../../../contexts/AuthContext';
import { useCart } from '../../../contexts/CartContext'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import {Link} from 'react-router-dom'


function NavTwo() {
    const { cart, handleShow } = useCart()
    const [count, setCount] = useState(cart.length)

    const {user, logout} = useAuth();

    useEffect(() => {
        setCount(cart.length)
    }, [cart])

    
    return (
        <Navbar bg="light" expand="lg">
            <Navbar.Brand href="#home"> <Link className="navbar-brand brand-logo" to="/"><img src={require('../../../../assets/images/logo.png')} alt="logo" /></Link>    </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link as={Link} to="/landing-page">Home</Nav.Link>
                    <Nav.Link as={Link} to="/">Dashboard</Nav.Link>
                    <Nav.Link>
                        <i className='mdi mdi-cart ' style={{ fontSize: 20 }} onClick={handleShow}></i>
                        <Badge variant="danger">{count}</Badge>
                    </Nav.Link>
                    {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                        <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                    </NavDropdown> */}
                </Nav>
                {user ?
                    <NavDropdown title={user.attributes.email} id="basic-nav-dropdown">
                    <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
                </NavDropdown>

                : <Link to='/user-pages/login-1?forward=landingPage'>Login</Link>}
                 
                       

            </Navbar.Collapse>
        </Navbar>
        // <nav className="navbar navbar-expand-lg navbar-light bg-light">
        //     <a className="navbar-brand" href="#">Navbar</a>
        //     <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        //         <span className="navbar-toggler-icon"></span>
        //     </button>
        //     <div className="collapse navbar-collapse" id="navbarNavDropdown">
        //         <ul className="navbar-nav">
        //             <li className="nav-item active">
        //                 <a className="nav-link" href="#">Home <span className="sr-only">(current)</span></a>
        //             </li>
        //             <li className="nav-item">
        //                 <a className="nav-link" href="#">Features</a>
        //             </li>
        //             <li className="nav-item">
        //                 <a className="nav-link" href="#">Pricing</a>
        //             </li>
        //             <li className='nav-item'>
        //                 <i className='mdi mdi-cart ' style={{ fontSize: 20 }} onClick={handleShow}></i>
        //                 <Badge variant="danger">{count}</Badge>

        //             </li>


        //         </ul>
        //     </div>
        // </nav>
    )
}

export default NavTwo
import React, { useEffect, useState, useRef } from 'react'
import { Modal, Button } from 'react-bootstrap';
import { useCart } from '../../../contexts/CartContext'
import { useAuth } from '../../../contexts/AuthContext'
import {Link} from 'react-router-dom'

function CheckoutModal() {

    const { show, handleClose, cart, setCart, updateCart, setUpdateCart } = useCart()
    const [test, setTest] = useState(false)
    const qtyRef = useRef();

    const { user } = useAuth();


    useEffect(() => {

        localStorage.setItem('cart', JSON.stringify(cart))

        
    },[test])

    const remove = (id) => {
        setCart(cart.filter((product) => product.id !== id))

    }

    const quantity = (id,value) => {

        console.log(qtyRef.current.value)

        const exist = cart.find(x => x.id === id)
        if(exist){
          cart.forEach((product) => {
                if(product.id === id)
                {
                   product.qty = value

                   

                }
            })
            
        }

        setTest(!test)

    }

   

    return (
        <>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Cart</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                                <div className="table-responsive-sm">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th className="w-5">Name</th>
                                                <th>QR</th>
                                                <th>Quantity</th>
                                                <th>TOT</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                cart ?
                                                    cart.map((product, index) => {
                                                        return (
                                                            <tr key={product.id}>
                                                                <td>{product.name.length > 5 ? product.name.substring(0,9) +'...': product.name}</td>
                                                                <td>{product.price}</td>
                                                                <td><input ref={qtyRef} className="form-control form-control-sm" type="number" min={1} placeholder="Quantity" pattern="\d*" defaultValue={product.qty} onChange={(e) => quantity(product.id, e.target.value)} /></td>
                                                                <td>{product.price * product.qty}</td>
                                                                <td><button className='btn btn-danger btn-sm' onClick={() => remove(product.id)}>X</button></td>
                                                            </tr>
                                                        )
                                                     }) : ''
                                            }

                                        </tbody>
                                    </table>
                                </div>
                           
                </Modal.Body>
                <Modal.Footer>
                    {user ?
                        <>
                            {cart.length > 0 ? <Link to='/purchase/checkout-page'><button className='btn btn-primary' onClick={handleClose}> Proceed to Checkout </button></Link> : <p style={{color: 'red'}}> Your cart is empty</p>}
                        </>
                        : <div className="alert alert-danger" role="alert">
                            Please Login Before You Checkout
                        </div>}


                </Modal.Footer>
            </Modal>
        </>
    );
}

export default CheckoutModal


import React, { Component, Suspense, lazy, useState, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ProtectedRoute } from './Routes/ProtectedRoutes'
import { AdminRoutes } from './Routes/AdminRoutes';
import Spinner from './shared/Spinner';
import { useAuth } from './contexts/AuthContext'



function AppRoutes() {

  const { user } = useAuth();
  const [isLoggedIn, setIsLoggedIn] = useState();

  useEffect(() => {
    if (user) {
      setIsLoggedIn(true)
    }
    else {
      setIsLoggedIn(false)
    }
  }, [user])

  const Dashboard = lazy(() => import('./dashboard/Dashboard'));
  const D = lazy(() => import('./dashboard/D'));
  const EditCard = lazy(() => import('./dashboard/EditCard'));
  const EditTag = lazy(() => import('./dashboard/EditTag'));
  const Analytic = lazy(() => import('./dashboard/Analytic'))
  const AddTap = lazy(() => import('./dashboard/AddTap'));
  const EditProfile = lazy(() => import('./dashboard/EditProfile'));
  

  //admin routes
  const RegisteredTaps = lazy(() => import('./dashboard/Admin/RegisteredTaps'));
  const RegisterTap = lazy(() => import('./dashboard/Admin/RegisterTap'));
  const EditRegisteredTag = lazy(() => import('./dashboard/Admin/EditRegisteredTag'));
  const EditPackages = lazy(() => import('./dashboard/Admin/EditPackages'));

  //purchase routes
  const LandingPage = lazy(() => import ('./PurchaseTag/pages/LandingPage'))
  const ProductDetails = lazy(() => import('./PurchaseTag/pages/ProductDetails'))
  const CartPage = lazy(() => import('./PurchaseTag/pages/CartPage'))
  //protected routes for purchase tags
  const CheckoutPage = lazy(() => import ('./PurchaseTag/pages/CheckoutPage'))



  const Buttons = lazy(() => import('./basic-ui/Buttons'));
  const Dropdowns = lazy(() => import('./basic-ui/Dropdowns'));
  const Typography = lazy(() => import('./basic-ui/Typography'));


  const BasicElements = lazy(() => import('./form-elements/BasicElements'));

  const BasicTable = lazy(() => import('./tables/BasicTable'));



  const Mdi = lazy(() => import('./icons/Mdi'));


  const ChartJs = lazy(() => import('./charts/ChartJs'));

  const Error404 = lazy(() => import('./error-pages/Error404'));
  const Error500 = lazy(() => import('./error-pages/Error500'));
  const LinkExpired = lazy(() => import('./error-pages/LinkExpired'));

  const Login = lazy(() => import('./user-pages/Login'));
  const Register1 = lazy(() => import('./user-pages/Register'));
  const EmailVerification = lazy(() => import('./user-pages/EmailVerification'));
  const ForgotPassword = lazy(() => import('./user-pages/ForgotPassword'))
  const Lockscreen = lazy(() => import('./user-pages/Lockscreen'));

  const BlankPage = lazy(() => import('./general-pages/BlankPage'));

  //public routes
  const TagDetails = lazy(() => import('./public-pages/TagDetails'));
  const VisitorLanding = lazy(() => import('./public-pages/VisitorLanding'));




  
  return (

    <Suspense fallback={<Spinner />}>
      <Switch>


        <ProtectedRoute exact path="/" component={D} />
        <ProtectedRoute exact path="/dashboard" component={D} />
        <ProtectedRoute path="/edit-card/:id" component={EditCard} />
        <ProtectedRoute path="/edit-tag" component={EditTag} />
        <ProtectedRoute path="/analytics/:id" component={Analytic} />
        <ProtectedRoute path="/add-tap" component={AddTap} />
        <ProtectedRoute path="/edit-profile" component={EditProfile} />

        <AdminRoutes path="/admin/registered-taps" component={RegisteredTaps} />
        <AdminRoutes path="/admin/register-new-tap" component={RegisterTap} />
        <AdminRoutes path="/admin/registered-tap-edit/:id" component={EditRegisteredTag} />
        <AdminRoutes path="/admin/edit-packages" component={EditPackages} />

        <ProtectedRoute path="/purchase/checkout-page" component={CheckoutPage}/>

        <AdminRoutes path="/admin/registered-taps" component={RegisteredTaps}/>
        <AdminRoutes path="/admin/register-new-tap" component={RegisterTap}/>
        <AdminRoutes path="/admin/registered-tap-edit/:id" component={EditRegisteredTag}/>

        <Route path="/landing-page" component={LandingPage}/>
        <Route path="/purchase/product-details/:price" component={ProductDetails}/>
        <Route path="/purchase/cart" component={CartPage} />

        <Route path="/tables/basic-table" component={BasicTable} />




        {/* <Route path="/basic-ui/buttons" component={Buttons} />
        <Route path="/basic-ui/dropdowns" component={Dropdowns} />
        <Route path="/basic-ui/typography" component={Typography} /> */}


        {/* <Route path="/form-Elements/basic-elements" component={BasicElements} />



        <Route path="/icons/mdi" component={Mdi} />


        <Route path="/charts/chart-js" component={ChartJs} /> */}


        <Route path="/user-pages/login-1" component={Login} />
        <Route path="/user-pages/register-1" component={Register1} />
        <Route path="/user-pages/email-verification/:email" component={EmailVerification} />
        <Route path="/user-pages/forgot-password" component={ForgotPassword} />
        <Route path="/link-expired" component={LinkExpired} />
        <Route path="/error-pages/error-404" component={Error404} />
        <Route path="/:tagUrl" component={VisitorLanding}/>
        {/* <Route path="/user-pages/lockscreen" component={Lockscreen} />

        <Route path="/error-pages/error-500" component={Error500} />

        <Route path="/general-pages/blank-page" component={BlankPage} /> */}

        {/* <Route path="/error-pages/error-404" component={Error404} />
        */}

      <Redirect exact to='/' /> 



      </Switch>
    </Suspense>

  )
}


// class AppRoutes extends Component {
//   render () {
//     return (
//       <Suspense fallback={<Spinner/>}>
//         <Switch>
//         <ProtectedRoute exact path="/dashboard" component={D} />




//           <Route path="/basic-ui/buttons" component={ Buttons } />
//           <Route path="/basic-ui/dropdowns" component={ Dropdowns } />
//           <Route path="/basic-ui/typography" component={ Typography } />


//           <Route path="/form-Elements/basic-elements" component={ BasicElements } />

//           <Route path="/tables/basic-table" component={ BasicTable } />


//           <Route path="/icons/mdi" component={ Mdi } />


//           <Route path="/charts/chart-js" component={ ChartJs } />


//           <Route path="/user-pages/login-1" component={ Login } />
//           <Route path="/user-pages/register-1" component={ Register1 } />
//           <Route path="/user-pages/lockscreen" component={ Lockscreen } />

//           <Route path="/error-pages/error-404" component={ Error404 } />
//           <Route path="/error-pages/error-500" component={ Error500 } />

//           <Route path="/general-pages/blank-page" component={ BlankPage } />


//           <Redirect to="/user-pages/login-1" />
//         </Switch>
//       </Suspense>
//     );
//   }
// }

export default AppRoutes;